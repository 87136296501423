import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import PartnerSection from '../../components/PartnerSection';
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import RsvpSectionS5 from '../../components/RsvpSectionS5/RsvpSectionS5';

const RsvpPage4 = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'}/>
            <PageTitle pageTitle={'R.S.V.P'} pagesub={'RSVP'} />
            <WeddingDate wClass={'wpo-wedding-date-s4'}/>
            <RsvpSectionS5 />
            <PartnerSection pClass={'pb-120 pt-0'}/>
            <Footer footerClass={'wpo-site-footer-s3'}/>
            <Scrollbar />
        </Fragment>
    )
};

export default RsvpPage4;
