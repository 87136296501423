import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { addToCart, addToWishList } from "../../store/actions/action";
import api from "../../api";
import Navbar3 from '../../components/Navbar3/Navbar3';
import Hero9 from '../../components/hero9/hero9';
import FunFact from '../../components/FunFact/FunFact';
import About2 from '../../components/about2/about2';
import ServiceSection2 from '../../components/ServiceSection2/ServiceSection2';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import Product from '../../components/Product';
import RsvpSectionS9 from '../../components/RsvpSectionS9/RsvpSectionS9';
import PricingSection from '../../components/PricingSection/PricingSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import PartnerSection from '../../components/PartnerSection';



const HomePlanner2 = ({ addToCart, addToWishList }) => {

    const productsArray = api();

    const addToCartProduct = (product, qty = 1) => {
        addToCart(product, qty);
    };

    const addToWishListProduct = (product) => {
        addToWishList(product);
    };

    const products = productsArray

    return (
        <Fragment>
            <Navbar3 hclass={'wpo-header-style-3'}/>
            <Hero9 />
            <FunFact fClass={'section-padding pb-0'}/>
            <About2 />
            <ServiceSection2 />
            <ProjectSection prClass={'section-bg'}/>
            <TeamSection/>
            <Testimonial/>
            <Product
                addToCartProduct={addToCartProduct}
                addToWishListProduct={addToWishListProduct}
                products={products}
            />
            <RsvpSectionS9/>
            <PricingSection/>
            <BlogSection bgClass={'wpo-blog-section-s3 soft-bg3'}/>
            <PartnerSection />
            <Footer footerClass={'wpo-site-footer-s5'} />
            <Scrollbar />
        </Fragment>
    )
};

export default connect(null, { addToCart, addToWishList })(HomePlanner2);