import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import PartnerSection from '../../components/PartnerSection';
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import RsvpSectionS9 from '../../components/RsvpSectionS9/RsvpSectionS9';

const RsvpPage7 = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'}/>
            <PageTitle pageTitle={'R.S.V.P'} pagesub={'RSVP'} />
            <WeddingDate wClass={'wpo-wedding-date-s4'}/>
            <RsvpSectionS9 />
            <PartnerSection pClass={'pb-120'}/>
            <Footer footerClass={'wpo-site-footer-s1'}/>
            <Scrollbar />
        </Fragment>
    )
};

export default RsvpPage7;
