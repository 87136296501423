import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import bg from '../../images/slider/invitation-2.jpg'

import shape1 from '../../images/slider/invitation-shape-1.png'
import shape2 from '../../images/slider/invitation-shape-2.png'

import LocationMap from './Modal';

const InvitationPage2 = () => {

    return (
        <Fragment>
            <div className="invitation-header">
                <Link className="back-btn" to="/"><i className="ti-back-left"></i></Link>
            </div>
            <section className="wpo-invitation-area-s2" style={{ backgroundImage: `url(${bg})` }}>
                <div className="hero-container">
                    <div className="hero-inner">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="wpo-event-wrap">
                                        <div className="wpo-event-item">
                                            <div className="wpo-event-text">
                                                <h2>James & Amelia</h2>
                                                <p>Our Big Day is November 15,2024</p>
                                                <ul>
                                                    <li>Monday, 12 Apr. 2024 <br />
                                                        1:00 PM – 2:30 PM </li>
                                                    <li>4517 Washington Ave. Manchester, Kentucky 39495</li>
                                                    <li>+1 234-567-8910</li>
                                                    <li><LocationMap /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="frame-shape-1"><img src={shape1} alt="" />
                                        </div>
                                        <div className="frame-shape-2"><img src={shape2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

export default InvitationPage2;