import React from "react";
import { Link } from 'react-router-dom'
import cImg1 from '../../images/category/1.jpg'
import cImg2 from '../../images/category/2.jpg'
import cImg3 from '../../images/category/3.jpg'
import cImg4 from '../../images/category/4.jpg'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const Categoey = [
    {
        sImg: cImg1,
        title: 'Royal Cake',
    },
    {
        sImg: cImg2,
        title: 'Chocolate Cake',
    },
    {
        sImg: cImg3,
        title: 'Fruit Decoration',
    },
    {
        sImg: cImg4,
        title: 'Cup Cake',
    }

]


const Categorys3 = () => {
    return (
        <section className="wpo-category-section-s3 section-padding">
            <h2 className="hidden">Category</h2>
            <div className="container">
                <div className="wpo-category-wrap">
                    <div className="row">
                        {
                            Categoey.map((cat, ct) => (
                                <div className="col-xl-3 col-lg-6 col-md-6 col-12" key={ct}>
                                    <div className="wpo-category-item">
                                        <div className="wpo-category-img">
                                            <div className="wpo-category-img-inner">
                                                <img src={cat.sImg} alt="" />
                                            </div>
                                        </div>
                                        <div className="wpo-category-text">
                                            <Link onClick={ClickHandler} to="/shop">{cat.title}</Link>
                                        </div>
                                    </div>
                                </div>
                            ))

                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Categorys3;