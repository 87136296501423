import React from "react";
import hero1 from '../../images/slider/hero-img-4.jpg'
import Shape1 from '../../images/slider/left-shape-3.png'
import Shape2 from '../../images/slider/left-shape-4.png'
import WeddingDate from "../WeddingDate/WeddingDate";

const Hero7 = () => {
    return (

        <section className="static-hero-s6">
            <div className="static-main-box">
                <div className="container-fluid">
                    <div className="static-inner-box">
                        <div className="row align-items-center">
                            <div className="col col-xl-7 col-lg-7 col-md-12 col-12">
                                <div className="hero-text">
                                    <h2>Esabella & William</h2>
                                    <div>
                                        <span>We Are Getting Married In</span>
                                    </div>
                                    <p>12 . 12 . 2024</p>
                                    <WeddingDate/>
                                </div>
                            </div>
                            <div className="col col-xl-5 col-lg-5 col-md-12 col-12">
                                <div className="wpo-static-hero-img">
                                    <div className="wpo-static-hero-ineer-img">
                                        <img src={hero1} alt=""/>
                                    </div>
                                    <div className="shape"><img src={Shape1} alt=""/></div>
                                    <div className="shape2"><img src={Shape2} alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero7;