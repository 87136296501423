import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import WeddingDate2 from '../../components/WeddingDate2/WeddingDate2';
import Hero5 from '../../components/hero5/hero5';
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import CoupleSection4 from '../../components/CoupleSection4/CoupleSection4';
import StorySection4 from '../../components/StorySection4/StorySection4';
import VideoSection from '../../components/VideoSection';
import PortfolioSectionS4 from '../../components/PortfolioSectionS4/PortfolioSectionS4';
import RsvpSectionS5 from '../../components/RsvpSectionS5/RsvpSectionS5';
import PartnerSection from '../../components/PartnerSection';
import BlogSection2 from '../../components/BlogSection2/BlogSection2';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'



const HomePage5 = () => {

    return (
        <Fragment>
            <Navbar2 hclass={'wpo-header-style-1'}/>
            <WeddingDate2 pbClass={'pb-0'}/>
            <Hero5 />
            <WeddingDate wClass={'wpo-wedding-date-s3'}/>
            <CoupleSection4 />
            <StorySection4 />
            <VideoSection />
            <PortfolioSectionS4 />
            <RsvpSectionS5 />
            <BlogSection2 bgClass={'pb-0'}/>
            <PartnerSection />
            <Footer footerClass={'wpo-site-footer-s3'}/> 
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage5;