import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import PartnerSection from '../../components/PartnerSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';

const PortfolioMasonaryPageS2 =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'}/>
            <PageTitle pageTitle={'Portfolio Masonary'} pagesub={'Portfolio'}/> 
            <ProjectSection ptClass={'pt-120 pb-0'}/>
            <PartnerSection pClass={'pb-120'}/>
            <Footer footerClass={'wpo-site-footer-s1'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default PortfolioMasonaryPageS2;
