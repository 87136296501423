import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pimg1 from '../../images/category/category1.svg'
import pimg2 from '../../images/category/category2.svg'
import pimg3 from '../../images/category/category3.svg'
import pimg4 from '../../images/category/category4.svg'
import pimg5 from '../../images/category/category5.svg'
import pimg6 from '../../images/category/category6.svg'
import pimg7 from '../../images/category/category7.svg'



const category = [
    {
        pImg: pimg1,
        title:'Wedding Dress',
    },
    {
        pImg: pimg2,
        title:'Wedding Jewelry',
    },
    {
        pImg: pimg3,
        title:'Flower Bouquet',
    },
    {
        pImg: pimg4,
        title:'Wedding Cake',
    },
    {
        pImg: pimg5,
        title:'Wedding Shoes',
    },
    {
        pImg: pimg6,
        title:'Photography',
    },
    {
        pImg: pimg7,
        title:'Wedding Gift',
    },
    {
        pImg: pimg2,
        title:'Wedding Cake',
    },
]


const Categorys = () => {

    var settings = {
        dots: false,
        arrows: false,
        speed: 1000,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (

        <section className="wpo-category-section section-padding">
            <h2 className="hidden">category</h2>
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="category-grids category-slider">
                            <Slider {...settings}>
                                {category.map((category, pitem) => (
                                    <div className="grid" key={pitem}>
                                        <img src={category.pImg} alt="" />
                                        <span>Wedding Dress</span>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Categorys;