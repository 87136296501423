import React from 'react'
import TimeCountDown from '../countdown'


const WeddingDate = (props) => {

    return (
        <section className={`wpo-wedding-date section-padding ${props.wClass}`}>
            <h2 className="d-none">hidden</h2>
            <div className="container">
                <div className="wedding-date-wrap">
                    <div className="clock-grids">
                        <div id="clock">
                            <TimeCountDown />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WeddingDate;