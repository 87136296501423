import React from "react";
import { Slide } from "react-awesome-reveal";
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import hero1 from '../../images/slider/slide-4.jpg'
import hero2 from '../../images/slider/slide-5.jpg'
import hero3 from '../../images/slider/slide-6.jpg'



const Hero9 = () => {
    return (

        <section className="wpo-hero-slider style-2">
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    <Swiper
                        // install Swiper modules
                        modules={[Pagination, A11y]}
                        spaceBetween={0}
                        slidesPerView={1}
                        loop={true}
                        pagination={{ clickable: true }}
                        speed={1800}
                        parallax={true}
                    >
                        <SwiperSlide>
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero2})` }}>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero3})` }}>
                            </div>
                        </SwiperSlide>
                        ...
                    </Swiper>
                </div>
            </div>
            <div className="slider-text">
                <Slide direction="up" duration="1000" triggerOnce="true">
                    <h2>Planning Your Everlasting Memories</h2>
                </Slide>
                <Slide direction="up" duration="1400" triggerOnce="true">
                    <p>Let’s Make Your Day Memorable. Your Successful Wedding is Our Job.</p>
                </Slide>
            </div>
        </section>

    )
}

export default Hero9;