import React from 'react'
import bg from '../../images/rsvp/bg3.jpg'
import VideoModal from '../ModalVideo/VideoModal';
import shape1 from '../../images/rsvp/top-shape2.png'
import shape2 from '../../images/rsvp/bottom-shape2.png'
import RSVPFrom from '../RSVPFrom/RSVPFrom';

const RsvpSectionS7 = (props) => {
    return (
        <section className={`wpo-contact-section-s7 ${props.rClass}`} id="rsvp">
            <div className="container">
                <div className="video-wrap">
                    <div className="contact-video">
                        <img src={bg} alt=""/>
                        <VideoModal/>
                    </div>

                    <div className="contact-wrap">
                        <div className="wpo-contact-section-wrapper">
                            <div className="wpo-contact-form-area">
                                <div className="wpo-section-title">
                                    <h2>Are You Attending?</h2>
                                </div>
                                <RSVPFrom />
                            </div>
                        </div>
                        <div className="shape-1"><img src={shape1} alt=""/></div>
                        <div className="shape-2"><img src={shape2} alt=""/></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RsvpSectionS7;