import React from "react";
import { Link } from 'react-router-dom'
import cImg1 from '../../images/category/img-1.jpg'
import cImg2 from '../../images/category/img-2.jpg'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const Categoey = [
    {
        sImg: cImg1,
        title: 'Limited Products',
        sub:'We have Just Few Products For You Hurry Up',
    },
    {
        sImg: cImg2,
        title: 'Trending Products',
        sub:'Here is Our Latest Collection',
    }

]


const Categorys2 = () => {
    return (
        <section className="wpo-category-section-s2">
            <h2 className="hidden">some</h2>
            <div className="container-fluid">
                <div className="wpo-category-wrap">
                    <div className="row">
                    {
                            Categoey.map((cat, ct) => (
                                <div className="col-md-6 col-sm-12 col-12" key={ct}>
                                    <div className="wpo-category-item">
                                        <div className="wpo-category-img">
                                            <img src={cat.sImg} alt="" />
                                        </div>
                                        <div className="wpo-category-text">
                                            <h3><Link onClick={ClickHandler} to="/shop">{cat.title}</Link></h3>
                                            <p>{cat.sub}</p>
                                        </div>
                                    </div>
                                </div>
                            ))

                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Categorys2;