import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import StorySection7 from '../../components/StorySection7/StorySection7';
import Scrollbar from '../../components/scrollbar/scrollbar'
import PartnerSection from '../../components/PartnerSection';

const StoryPage7 = () => {
    return (
        <Fragment className="rtl-page">
            <Navbar hclass={'wpo-header-style-1'}/>
            <PageTitle pageTitle={'Our Story'} pagesub={'Story'} />
            <div dir="rtl" className="rtl-page">
                <StorySection7 />
            </div>
            <PartnerSection pClass={'section-padding pt-0'}/>
            <Footer/>
            <Scrollbar />
        </Fragment>
    )
};

export default StoryPage7;
