import React from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/couple/couple-img-3.jpg'
import coupleImg2 from '../../images/couple/couple-img-4.jpg'
import icon1 from '../../images/couple/bride2.svg'
import icon2 from '../../images/couple/groom2.svg'
import heart from '../../images/couple/heart-shape.png'
import MarqueeTitle from '../MarqueeTitle';


const CoupleSection2 = (props) => {

    return (
        <section className="wpo-couple-section-s2 section-padding pt-0" id="couple">
            <div className="container-fluid">
                <div className="couple-area clearfix">
                    <div className="couple-wrap">
                        <div className="row gx-5">
                            <div className="col col-lg-5 col-12">
                                <div className="couple-item">
                                    <div className="couple-img">
                                        <img src={coupleImg1} alt="" />
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-text-inner">
                                            <i><img src={icon1} alt="" /></i>
                                            <h3>Esabella Bell</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                                vitae nisl. fringilla pellesque amet tempus.</p>
                                            <div className="social">
                                                <ul>
                                                    <li><Link to="/home-2"><i className="ti-facebook"></i></Link></li>
                                                    <li><Link to="/home-2"><i className="ti-twitter-alt"></i></Link></li>
                                                    <li><Link to="/home-2"><i className="ti-instagram"></i></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="love-shape"><img src={heart} alt="" /></div>
                            </div>
                            <div className="col col-lg-5 col-12">
                                <div className="couple-item">
                                    <div className="couple-img">
                                        <img src={coupleImg2} alt="" />
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-text-inner">
                                            <i><img src={icon2} alt="" /></i>
                                            <h3>William Max</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                                vitae nisl. fringilla pellesque amet tempus.</p>
                                            <div className="social">
                                                <ul>
                                                    <li><Link to="/home-2"><i className="ti-facebook"></i></Link></li>
                                                    <li><Link to="/home-2"><i className="ti-twitter-alt"></i></Link></li>
                                                    <li><Link to="/home-2"><i className="ti-instagram"></i></Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MarqueeTitle/>
        </section>
    );
}

export default CoupleSection2;