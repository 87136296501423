import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { addToCart, addToWishList } from "../../store/actions/action";
import api from "../../api";
import Navbar from '../../components/Navbar/Navbar'
import Hero8 from '../../components/hero8/hero8';
import About from '../../components/about/about';
import FunFact from '../../components/FunFact/FunFact';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import Product from '../../components/Product';
import RsvpSectionS9 from '../../components/RsvpSectionS9/RsvpSectionS9';
import PricingSection from '../../components/PricingSection/PricingSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import PartnerSection from '../../components/PartnerSection';



const HomePlanner1 = ({ addToCart, addToWishList }) => {

    const productsArray = api();

    const addToCartProduct = (product, qty = 1) => {
        addToCart(product, qty);
    };

    const addToWishListProduct = (product) => {
        addToWishList(product);
    };

    const products = productsArray

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'}/>
            <Hero8 />
            <About />
            <FunFact fClass={'pt-120'}/>
            <ServiceSection sClass={'pt-0'}/>
            <ProjectSection prClass={'section-bg'}/>
            <TeamSection/>
            <Testimonial/>
            <Product
                addToCartProduct={addToCartProduct}
                addToWishListProduct={addToWishListProduct}
                products={products}
            />
            <RsvpSectionS9/>
            <PricingSection/>
            <BlogSection bgClass={'wpo-blog-section-s3 soft-bg3'}/>
            <PartnerSection />
            <Footer footerClass={'wpo-site-footer-s5'} />
            <Scrollbar />
        </Fragment>
    )
};

export default connect(null, { addToCart, addToWishList })(HomePlanner1);