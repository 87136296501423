import React from 'react'
import Shape1 from '../../images/slider/heading-shape-1.png'
import Shape2 from '../../images/slider/heading-shape-2.png'


const WeddingDate2 = (props) => {

    return (
        <div className="wpo-hero-date">
            <h2>
                <span className="shape-1"><img src={Shape1} alt=""/></span>
                Esabella & William
                <span className="shape-2"><img src={Shape2} alt=""/> </span>
            </h2>
            <p>We Are Getting Married November 15,2024</p>
        </div>
    )
}

export default WeddingDate2;