import React from 'react'
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/story/story-17.jpg'
import sImg2 from '../../images/story/story-18.jpg'
import sImg3 from '../../images/story/story-19.jpg'

import shape1 from '../../images/story/flower-shape-3.png'
import shape2 from '../../images/story/flower-shape-4.png'


const Storys = [
    {
        sImg: sImg1,
        title: 'First Time We Meet',
        date: '15 June 2014',
        description: 'Consectetur adipiscing elit. Fringilla at risus orci, tempus facilisi sed. Enim tortor, faucibus netus orci donec volutpat adipiscing. Sit condimentum elit convallis libero. Nunc in eu tellus ipsum placerat.',
    },
    {
        sImg: sImg2,
        title: 'Our First Date',
        date: '12 Dec 2019',
        description: 'Consectetur adipiscing elit. Fringilla at risus orci, tempus facilisi sed. Enim tortor, faucibus netus orci donec volutpat adipiscing. Sit condimentum elit convallis libero. Nunc in eu tellus ipsum placerat.',
        order1: 'order-lg-2 order-1',
        order2: 'order-lg-1 order-2',
    },
    {
        sImg: sImg3,
        title: 'She Said Yes!',
        date: '16 Jan 2023',
        description: 'Consectetur adipiscing elit. Fringilla at risus orci, tempus facilisi sed. Enim tortor, faucibus netus orci donec volutpat adipiscing. Sit condimentum elit convallis libero. Nunc in eu tellus ipsum placerat.',
    }

]



const StorySection6 = (props) => {
    return (

        <section className="wpo-story-section-s6 section-padding" id="story">
            <div className="container-fluid">
                <SectionTitle subTitle={'Our Story'} MainTitle={'Our Sweet Love Story'} />
                <div className="wpo-story-wrap">
                    {
                        Storys.map((story, st) => (
                            <div className="wpo-story-item" key={st}>
                                <div className="wpo-story-img-wrap">
                                    <div className="wpo-story-img">
                                        <img src={story.sImg} alt="" />
                                        <div className="wpo-img-shape">
                                            <img src={shape1} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="wpo-story-content">
                                    <div className="wpo-story-content-inner">
                                        <h2>{story.title}</h2>
                                        <span>{story.date}</span>
                                        <p>{story.description}</p>

                                        <div className="inner-shape">
                                            <img src={shape2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))

                    }
                </div>
            </div>
        </section>
    )
}

export default StorySection6;