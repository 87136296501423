import React from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/couple/groom.png'
import coupleImg2 from '../../images/couple/bride.png'
import icon1 from '../../images/couple/groom.svg'
import icon2 from '../../images/couple/bride.svg'
import love from '../../images/couple/love.png'

const CoupleSection7 = (props) => {

    return (
        <section className="wpo-couple-section-s6 section-padding" id="couple">
            <div className="container-fluid">
                <div className="wpo-couple-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="text-grid bride">
                                <div className="couple-text">
                                    <i><img src={icon1} alt="" /></i>
                                    <h3>عمران حسين</h3>
                                    <p>عمران شخص عظيم. هو نجل أبو بكر شيكر ، شخص طيب ومجتهد. إنه مطور رائع ومدير تنفيذي
                                        رائع لشركته.
                                    </p>
                                    <div className="social">
                                        <ul>
                                            <li><Link to="/home3"><i className="ti-facebook"></i></Link></li>
                                            <li><Link to="/home3"><i className="ti-twitter-alt"></i></Link></li>
                                            <li><Link to="/home3"><i className="ti-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="couple-img">
                                    <img src={coupleImg1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="middle-love-pic">
                                <img src={love} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="text-grid groom">
                                <div className="couple-img">
                                    <img src={coupleImg2} alt="" />
                                </div>
                                <div className="couple-text">
                                    <i><img src={icon2} alt="" /></i>
                                    <h3>نعيمة أنجم</h3>
                                    <p>نعيمة إنسانة عظيمة. هي ابنة محمد امزاد حسين. إنها شخص لطيف ولطيف. عة ومديرة
                                        تنفيذية ممتازة لشركته.
                                    </p>
                                    <div className="social">
                                        <ul>
                                            <li><Link to="/home3"><i className="ti-facebook"></i></Link></li>
                                            <li><Link to="/home3"><i className="ti-twitter-alt"></i></Link></li>
                                            <li><Link to="/home3"><i className="ti-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoupleSection7;