import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/h1.jpg'
import hero2 from '../../images/slider/h2.jpg'
import hero3 from '../../images/slider/h3.jpg'
import hero4 from '../../images/slider/h4.jpg'
import hero5 from '../../images/slider/h5.jpg'
import hero6 from '../../images/slider/h6.jpg'
import hero7 from '../../images/slider/h7.jpg'


var settings = {
    dots: false,
    arrows: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const HeroArray = [
    {
        hImg: hero1,
    },
    {
        hImg: hero2,
    },
    {
        hImg: hero3,
    },
    {
        hImg: hero4,
    },
    {
        hImg: hero5,
    },
    {
        hImg: hero6,
    },
    {
        hImg: hero7,
    },
]


const Hero8 = () => {
    return (

        <section className="wpo-hero-section">
            <h2 className="hidden">hidden</h2>
            <div className="container-fluid">
                <div className="wpo-hero-items">
                    <Slider {...settings}>
                        {
                            HeroArray.map((hero, hr) => (
                                <div className="wpo-hero-item" key={hr}>
                                    <div className="wpo-hero-img">
                                        <img src={hero.hImg} alt="" />
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Hero8;