import React from 'react'
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/story/story-13.jpg'
import sImg2 from '../../images/story/story-14.jpg'
import sImg3 from '../../images/story/story-15.jpg'
import sImg4 from '../../images/story/story-16.jpg'


const Storys = [
    {
        sImg: sImg1,
        title: 'First Time We Meet',
        date: '15 June 2014'
    },
    {
        sImg: sImg2,
        title: 'Our First Date',
        date: '12 Dec 2019',
    },
    {
        sImg: sImg3,
        title: 'Our First Kiss',
        date: '16 Jan 2020'
    },
    {
        sImg: sImg4,
        title: 'She Said Yes!',
        date: '16 Jan 2022'
    }

]



const StorySection5 = (props) => {

    return (
        <section className="wpo-story-section-s5 section-padding pb-0" id="story">
            <div className="container-fluid">
                <SectionTitle subTitle={'Our Story'} MainTitle={'Our Sweet Love Story'} />
                <div className="wpo-story-wrap">
                    <div className="row">
                        {
                            Storys.map((story, st) => (
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={st}>
                                    <div className="wpo-story-item">
                                        <div className="story-img-wrap">
                                            <div className="wpo-story-img">
                                                <img src={story.sImg} alt="" />
                                            </div>
                                        </div>
                                        <div className="wpo-story-content">
                                            <h2>{story.title}</h2>
                                            <span>{story.date}</span>
                                        </div>
                                    </div>
                                </div>
                            ))

                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection5;