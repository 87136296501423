import React from 'react'
import cimg from '../../images/rsvp/left-shape2.png'
import cimg2 from '../../images/rsvp/right-shape2.png'
import bg from '../../images/rsvp/rsvp-bg.png'

import RSVPFrom from '../RSVPFrom/RSVPFrom'
import MarqueeTitle from '../MarqueeTitle'


const RsvpSectionS2 = (props) => {
    return (
        <section className={`wpo-contact-section-s2 section-padding ${props.rClass}`}  id="rsvp">
            <div className="container">
                <div className="contact-wrap">
                    <div className="wpo-contact-section-wrapper">
                        <div className="wpo-contact-form-area">
                            <div className="wpo-section-title">
                                <h2>Are You Attending?</h2>
                            </div>
                            <RSVPFrom />
                        </div>
                    </div>
                    <div className="back-shape">
                        <img src={bg} alt=""/>
                    </div>
                    <div className="shape-1"><img
                            src={cimg} alt=""/></div>
                    <div className="shape-2"><img
                            src={cimg2} alt=""/></div>
                </div>
            </div>
            <MarqueeTitle/>
        </section>
    )
}

export default RsvpSectionS2;