import React from "react";
import { Link } from 'react-router-dom'

import hero1 from '../../images/slider/hero-3.jpg'
import icon1 from '../../images/couple/bride2.svg'
import icon2 from '../../images/couple/groom2.svg'
import shape1 from '../../images/slider/shape-1.png'
import shape2 from '../../images/slider/shape-2.png'
import shape3 from '../../images/slider/shape-3.png'



const Hero3 = () => {
    return (

        <section className="static-hero-s3">
            <div className="static-main-box">
                <div className="container-fluid">
                    <div className="static-inner-box">
                        <div className="row align-items-center">
                            <div className="col col-xl-3 col-sm-6 col-lg-3 col-12 order-lg-1 order-2">
                                <div className="couple-text">
                                    <div className="couple-text-inner">
                                        <i><img src={icon1} alt=""/></i>
                                        <h3>Esabella Bell</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                            vitae nisl. fringilla pellesque amet tempus.</p>
                                        <div className="social">
                                            <ul>
                                                <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                                <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                                <li><Link to="/"><i className="ti-instagram"></i></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="shape1"><img src={shape1} alt=""/></div>
                                    <div className="shape2"><img src={shape2} alt=""/></div>
                                    <div className="shape3"><img src={shape3} alt=""/></div>
                                </div>
                            </div>
                            <div className="col col-xl-6 col-lg-6 col-md-8 col-12 order-lg-2 order-1">
                                <div className="wpo-static-hero-img">
                                    <div className="wpo-static-hero-ineer-img">
                                        <img src={hero1} alt=""/>

                                        <div className="hero-text">
                                            <span>We Are Getting Married In</span>
                                            <p>12 . 12 . 2024</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-3 col-lg-3 col-sm-6 col-12 order-lg-3 order-3">
                                <div className="couple-text">
                                    <div className="couple-text-inner">
                                        <i><img src={icon2} alt=""/></i>
                                        <h3>William Max</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                            vitae nisl. fringilla pellesque amet tempus.</p>
                                        <div className="social">
                                            <ul>
                                                <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                                <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                                <li><Link to="/"><i className="ti-instagram"></i></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="shape1"><img src={shape1} alt=""/></div>
                                    <div className="shape2"><img src={shape2} alt=""/></div>
                                    <div className="shape3"><img src={shape3} alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero3;