import React from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/couple/couple-img-5.jpg'
import coupleImg2 from '../../images/couple/couple-img-6.jpg'
import coupleImg3 from '../../images/couple/couple-img-9.jpg'
import coupleImg4 from '../../images/couple/couple-img-10.jpg'
import coupleImg5 from '../../images/couple/couple-img-7.jpg'
import coupleImg6 from '../../images/couple/couple-img-8.jpg'
import vector1 from '../../images/couple/l-shape-1.png'
import vector2 from '../../images/couple/l-shape-2.png'
import vector3 from '../../images/couple/l-shape-3.png'
import vector4 from '../../images/couple/l-shape-4.png'


const CoupleSection3 = (props) => {

    return (
        <section className="wpo-couple-section-s3 section-padding">
            <div className="container-fluid">
                <div className="row gx-5 align-items-center">
                    <div className="col-lg-4 col-md-6 order-lg-1 order-1">
                        <div className="couple-left">
                            <div className="couple-item">
                                <div className="couple-img">
                                    <img src={coupleImg1} alt=""/>
                                    <div className="l-shape"><img src={vector1} alt=""/></div>
                                </div>
                                <div className="couple-text">
                                    <h2>First Time We Meet</h2>
                                    <span>12 Feb 2016</span>
                                </div>
                            </div>
                            <div className="couple-item">
                                <div className="couple-img">
                                    <img src={coupleImg2} alt=""/>
                                    <div className="l-shape"><img src={vector2} alt=""/></div>
                                </div>
                                <div className="couple-text">
                                    <h2>Our First Kiss</h2>
                                    <span>12 Feb 2017</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 order-lg-2 order-3">
                        <div className="couple-middle">
                            <div className="couple-middle-item">
                                <div className="couple-img">
                                    <img src={coupleImg3} alt=""/>
                                </div>
                                <div className="couple-text">
                                    <h3>Esabella Bell</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                        vitae nisl. fringilla pellesque amet tempus.</p>
                                    <div className="social">
                                        <ul>
                                        <li><Link to="/home-3"><i className="ti-facebook"></i></Link></li>
                                        <li><Link to="/home-3"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link to="/home-3"><i className="ti-instagram"></i></Link></li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="couple-middle-love-text">
                                <h2 className="poort-text poort-in-up">
                                    <span>L</span>
                                    <span>o</span>
                                    <span>v</span>
                                    <span>e</span>
                                </h2>
                            </div>
                            <div className="couple-middle-item">
                                <div className="couple-img">
                                    <img src={coupleImg4} alt=""/>
                                </div>
                                <div className="couple-text">
                                    <h3>William Max</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                        vitae nisl. fringilla pellesque amet tempus.</p>
                                    <div className="social">
                                        <ul>
                                        <li><Link to="/home-3"><i className="ti-facebook"></i></Link></li>
                                        <li><Link to="/home-3"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link to="/home-3"><i className="ti-instagram"></i></Link></li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 order-lg-3 order-2">
                        <div className="couple-right">
                            <div className="couple-item">
                                <div className="couple-img">
                                    <img src={coupleImg5} alt=""/>
                                    <div className="l-shape"><img src={vector3} alt=""/></div>
                                </div>
                                <div className="couple-text">
                                    <h2>Our First Date</h2>
                                    <span>12 Feb 2018</span>
                                </div>
                            </div>
                            <div className="couple-item">
                                <div className="couple-img">
                                    <img src={coupleImg6} alt=""/>
                                    <div className="l-shape"><img src={vector4} alt=""/></div>
                                </div>
                                <div className="couple-text">
                                    <h2>She Said Yes!</h2>
                                    <span>12 Feb 2019</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoupleSection3;