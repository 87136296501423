import simg1 from '../images/service/img-1.jpg'
import simg2 from '../images/service/img-2.jpg'
import simg3 from '../images/service/img-3.jpg'
import simg4 from '../images/service/img-4.jpg'

import sbimg1 from '../images/service/img-5.jpg'
import sbimg2 from '../images/service/img-6.jpg'
import sbimg3 from '../images/service/img-7.jpg'
import sbimg4 from '../images/service/img-8.jpg'

import sinnermg1 from '../images/service-single/2.jpg'
import sinnermg2 from '../images/service-single/3.jpg'

import singleImg1 from '../images/service-single/1.jpg'
import singleImg2 from '../images/service-single/img-2.jpg'
import singleImg3 from '../images/service-single/img-3.jpg'
import singleImg4 from '../images/service-single/img-4.jpg'
import singleImg5 from '../images/service-single/1.jpg'


const Services = [
    {
        id: '1',
        title:'Wedding Dress',
        slug:'Wedding-Dress',
        description:'Aliquet magna menas esmod pelque diam. Maenas massa tincint bidum tidunt.',
        simg:simg1,
        sbimg:sbimg1,
        ssImg:singleImg1,
        sinnerImg1:sinnermg1,
        sinnerImg2:sinnermg2,
    },
    {
        id: '2',
        title:'Event Planning',
        slug:'Event-Planning',
        description:'Aliquet magna menas esmod pelque diam. Maenas massa tincint bidum tidunt.',
        simg:simg2,
        sbimg:sbimg2,
        ssImg:singleImg2,
        sinnerImg1:sinnermg1,
        sinnerImg2:sinnermg2,
    },
    {
        id: '3',
        title:'Photography',
        slug:'Photography',
        description:'Aliquet magna menas esmod pelque diam. Maenas massa tincint bidum tidunt.',
        simg:simg3,
        sbimg:sbimg3,
        ssImg:singleImg3,
        sinnerImg1:sinnermg1,
        sinnerImg2:sinnermg2,
    },
    {
        id: '4',
        title:'Cake Design',
        slug:'Cake-Design',
        description:'Aliquet magna menas esmod pelque diam. Maenas massa tincint bidum tidunt.',
        simg:simg4,
        sbimg:sbimg4,
        ssImg:singleImg4,
        sinnerImg1:sinnermg1,
        sinnerImg2:sinnermg2,
    },
    {
        id: '5',
        title:'Wedding Registry',
        slug:'Wedding-Registry',
        description:'Aliquet magna menas esmod pelque diam. Maenas massa tincint bidum tidunt.',
        ssImg:singleImg5,
        sinnerImg1:sinnermg1,
        sinnerImg2:sinnermg2,
    }
]
export default Services;