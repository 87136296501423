import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import CoupleSection from '../../components/CoupleSection/CoupleSection';
import EventSection from '../../components/EventSection/EventSection';
import VideoSection2 from '../../components/VideoSection2';
import PartnerSection from '../../components/PartnerSection';


const AccomodationPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'}/>
            <PageTitle pageTitle={'Accomodation'} pagesub={'Accomodation'}/> 
            <WeddingDate/>
            <CoupleSection cClass={'pt-160'}/>
            <VideoSection2/>
            <EventSection/>
            <PartnerSection pClass={'section-padding pt-0'} />
            <Footer footerClass={'wpo-site-footer'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default AccomodationPage;
