import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Hero7 from '../../components/hero7/hero7';
import CoupleSection6 from '../../components/CoupleSection6/CoupleSection6';
import StorySection6 from '../../components/StorySection6/StorySection6';
import RsvpSectionS7 from '../../components/RsvpSectionS7/RsvpSectionS7';
import PartnerSection from '../../components/PartnerSection';
import EventSection2 from '../../components/EventSection2/EventSection2';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'

const HomePage7 = () => {

    return (
        <Fragment>
            <Navbar2 hclass={'wpo-header-style-2'}/>
            <Hero7 />
            <CoupleSection6 />
            <StorySection6 />
            <RsvpSectionS7 />
            <EventSection2/>
            <BlogSection bgClass={'wpo-blog-section-s3 soft-bg2 section-padding'}/>
            <PartnerSection />
            <Footer footerClass={'wpo-site-footer-s4'}/>
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage7;