import React from "react";
import hero1 from '../../images/slider/hero-img-3.jpg'
import frame from '../../images/slider/back-flower2.png'
import shape1 from '../../images/slider/vector1.png'
import shape2 from '../../images/slider/vector2.png'
import calender from '../../images/slider/calender.png'


const Hero6 = () => {
    return (

        <section className="static-hero-s5">
            <div className="static-main-box">
                <div className="container-fluid">
                    <div className="static-inner-box">
                        <div className="row align-items-center">
                            <div className="col col-xl-6 col-lg-7 col-md-12 col-12">
                                <div className="wpo-static-hero-img">
                                    <div className="wpo-static-hero-ineer-img">
                                        <img src={hero1} alt=""/>
                                    </div>
                                    <div className="back-flower">
                                        <img src={frame} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-6 col-lg-5 col-md-12 col-12">
                                <div className="hero-text">
                                    <div className="vector-1">
                                        <img src={shape1} alt=""/>
                                    </div>
                                    <h3>Manshi & Akash</h3>
                                    <h4> December 2024</h4>
                                    <div className="calender">
                                        <img src={calender} alt=""/>
                                    </div>
                                    <div className="vector-2">
                                        <img src={shape2} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero6;