import React from 'react'
import VideoModal from '../ModalVideo/VideoModal'
import vImg from '../../images/video-img/img-1.jpg'


const VideoSection2 = (props) => {
    return (
        <section className="wpo-video-section-s2 section-padding">
            <h2 className="hidden">some</h2>
            <div className="container">
                <div className="video-wrap">
                    <img src={vImg} alt=""/>
                    <VideoModal />
                </div>
            </div>
        </section>
    )
}

export default VideoSection2;