import React from "react";
import hero1 from '../../images/slider/hero-1.jpg'
import hero2 from '../../images/slider/hero-2.jpg'
import shape1 from '../../images/slider/left-shape-1.png'
import shape2 from '../../images/slider/left-shape-2.png'


const Hero2 = () => {
    return (
        <section className="static-hero-s2">
            <div className="static-main-box">
                <div className="container-fluid">
                    <div className="static-inner-box">
                        <div className="row align-items-center">
                            <div className="col col-xl-3 col-sm-6 col-lg-3 col-12 order-lg-1 order-1">
                                <div className="static-hero-img">
                                    <img src={hero1} alt=""/>
                                </div>
                            </div>
                            <div className="col col-xl-6 col-lg-6 col-md-8 col-12 order-lg-2 order-3">
                                <div className="wpo-static-hero-text-wrap">
                                    <div className="wpo-static-hero-text-box">
                                        <div className="slide-title">
                                            <h2 className="poort-text poort-in-up">Esabella & William</h2>
                                        </div>
                                        <div className="slide-text">
                                            <p>We Are Getting Married In </p>
                                        </div>
                                        <div className="slide-date">
                                            <p>12 . 12 . 2024</p>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="shape"><img
                                            src={shape1} alt=""/></div>
                                    <div className="shape2"><img
                                            src={shape2} alt=""/></div>
                                </div>
                            </div>
                            <div className="col col-xl-3 col-lg-3 col-sm-6 col-12 order-lg-3 order-2">
                                <div className="static-hero-img">
                                    <img src={hero2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero2;