import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const testimonial = [
    {
        id: '01',
        Des: "Varius aenean fringilla consectetur adipiscing felis, lectus. Id eros, porta quam quis proin non vulputate lacinia imperdiet. Mus ut amet tortor iEros, sed at semper sed in tempor ultrices sed. Id sem nulla quisque vel duiscoue necrd.",
        Title: 'Marlin & Williamson',
        Date: "23.05.2024",
    },
    {
        id: '02',
        Des: "Varius aenean fringilla consectetur adipiscing felis, lectus. Id eros, porta quam quis proin non vulputate lacinia imperdiet. Mus ut amet tortor iEros, sed at semper sed in tempor ultrices sed. Id sem nulla quisque vel duiscoue necrd.",
        Title: 'Rihanna & William',
        Date: "08.12.2024",
    },
    {
        id: '03',
        Des: "Varius aenean fringilla consectetur adipiscing felis, lectus. Id eros, porta quam quis proin non vulputate lacinia imperdiet. Mus ut amet tortor iEros, sed at semper sed in tempor ultrices sed. Id sem nulla quisque vel duiscoue necrd.",
        Title: 'Sarah & Daniel',
        Date: "12.08.2024",
    }
]

const Testimonial = () => {

    const settings = {
        dots: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };


    return (
        <section className="wpo-testimonial-section section-padding">
            <div className="container-fluid">
                <div className="wpo-testimonial-wrap">
                    <div className="row justify-content-center">
                        <div className="col col-xl-6 col-lg-8 col-12">
                            <div className="wpo-testimonial-items">
                                <div className="wpo-testimonial-active">
                                    <Slider {...settings}>
                                        {
                                            testimonial.map((tesmnl, tsm) => (
                                                <div className="wpo-testimonial-item" key={tsm}>
                                                    <div className="wpo-testimonial-text">
                                                        <i className="ti-quote-right"></i>
                                                        <p>"{tesmnl.Des}"</p>
                                                        <div className="wpo-testimonial-text-btm">
                                                            <h3>{tesmnl.Title}</h3>
                                                            <span>Wedding- {tesmnl.Date}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;