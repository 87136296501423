import React from "react";
import { Slide } from "react-awesome-reveal";
import hero1 from '../../images/slider/hero-img-1.png'
import shape from '../../images/slider/bottom-image.png'


const Hero2 = () => {
    return (
        <section className="static-hero">
            <div className="static-main-box">
                <div className="static-inner-box">
                    <div className="container">
                        <div className="row">
                            <div className="col col-xl-6 col-lg-6 col-12">
                                <div className="static-hero-img">
                                    <img src={hero1} alt="" />
                                    <div className="hero-img-inner-shape">
                                        <svg viewBox="0 0 550 814" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M520 0.804281C529.932 0.270383 539.935 0 550 0V30V784V814H520H30.0002H0.000244141V784V550C0.000244141 256.309 230.195 16.3825 520 0.804281ZM95 298.06C85.7573 314.717 77.4026 331.934 70 349.649V784H95V298.06ZM119 258.979V784H144V225.064C135.224 236.015 126.882 247.329 119 258.979ZM192 784H168V197.185C175.733 188.816 183.738 180.702 192 172.857V784ZM215 784H241V131.725C232.112 138.302 223.441 145.156 215 152.273V784ZM290 784H264V115.645C272.496 110.04 281.166 104.677 290 99.5667V784ZM314 784H338.25V74.9247C330.053 78.5841 321.967 82.4494 314 86.5141V784ZM386.834 784H362.084V64.992C370.236 61.8311 378.488 58.8699 386.834 56.1142V784ZM410.667 784H434.5V42.8728C426.484 44.6909 418.538 46.6941 410.667 48.8779V784ZM481.25 784H458.334V38.0538C465.917 36.7051 473.557 35.5207 481.25 34.5046V784ZM505.084 784H520V30.8509C515.009 31.1348 510.036 31.4892 505.084 31.9129V784ZM30.0002 550C30.0002 504.255 35.9072 459.889 47 417.624V784H30.0002V550Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-6 col-lg-6 col-12">
                                <div className="wpo-static-hero-text-box">
                                    <div className="slide-title">
                                        <Slide direction="up" duration="1000" triggerOnce="true">
                                            <h2 className="poort-text poort-in-up">Esabella & William</h2>
                                        </Slide>
                                    </div>
                                    <div className="slide-text">
                                        <Slide direction="up" duration="1200" triggerOnce="true">
                                            <p>We Are Getting Married In </p>
                                        </Slide>
                                    </div>
                                    <div className="slide-date">
                                        <Slide direction="up" duration="1400" triggerOnce="true">
                                            <p>12 . 12 . 2024</p>
                                        </Slide>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-shape">
                    <Slide direction="up" duration="1000" triggerOnce="true">
                        <img src={shape} alt="" />
                    </Slide>
                </div>
            </div>
        </section>
    )
}

export default Hero2;