import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import About2 from '../../components/about2/about2';
import ServiceSectionS2 from '../../components/ServiceSection2/ServiceSection2';
import Testimonial from '../../components/Testimonial/Testimonial';
import BlogSection from '../../components/BlogSection/BlogSection';
import FunFact from '../../components/FunFact/FunFact';
import PricingSection from '../../components/PricingSection/PricingSection';
import PartnerSection from '../../components/PartnerSection';

const AboutPage = () => {
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'}/>
            <PageTitle pageTitle={'About Us'} pagesub={'About'} />
            <About2 />
            <FunFact fClass={'section-padding'}/>
            <ServiceSectionS2 sClass={'pt-0'}/>
            <Testimonial/>
            <PricingSection/>
            <BlogSection />
            <PartnerSection pClass={'section-padding'}/>
            <Footer footerClass={'wpo-site-footer-s1'} />
            <Scrollbar />
        </Fragment>
    )
};

export default AboutPage;
