import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/Hero';
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import CoupleSection from '../../components/CoupleSection/CoupleSection';
import StorySection from '../../components/StorySection';
import PortfolioSection from '../../components/PortfolioSection';
import RsvpSection from '../../components/RsvpSection/RsvpSection';
import EventSection from '../../components/EventSection/EventSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import PartnerSection from '../../components/PartnerSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';



const HomePage = () => {

    return (
        <Fragment>
            <Navbar />
            <Hero />
            <WeddingDate />
            <CoupleSection />
            <StorySection />
            <PortfolioSection prClass={'pt-0'}/>
            <RsvpSection rClass={"pt-0"}/>
            <EventSection/>
            <BlogSection />
            <PartnerSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage;