import React from 'react'
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/shape-1.png'
import sImg2 from '../../images/event/shape-2.png'
import Icon1 from '../../images/icon/1.svg'
import Icon2 from '../../images/icon/2.svg'
import Icon3 from '../../images/icon/3.svg'
import Icon4 from '../../images/icon/4.svg'
import Icon5 from '../../images/icon/5.svg'
import Icon6 from '../../images/icon/6.svg'
import Icon7 from '../../images/icon/7.svg'


const Events = [
    {
        title: 'Welcome Drinks',
        time:'2.00 PM',
        Icon:Icon1,
    },
    {
        title: 'Ceremony',
        time:'3.00 PM',
        Icon:Icon2,
    },
    {
        title: 'Party Photos',
        time:'5.00 PM',
        Icon:Icon3,
    },
    {
        title: 'Dinner',
        time:'7.00 PM',
        Icon:Icon4,
    },
    {
        title: 'Cake Cutting',
        time:'9.00 PM',
        Icon:Icon5,
    },
    {
        title: 'First Dance',
        time:'10.00 PM',
        Icon:Icon6,
    },
    {
        title: 'Depart',
        time:'11.00 PM',
        Icon:Icon7,
    },

]

const EventSection = (props) => {
    return (
        <section className="wpo-event-section section-padding pt-0" id="event">
            <div className="container">
                <SectionTitle subTitle={'When & Where'} MainTitle={'Our Wedding Programs'} />
                <div className="wpo-event-main">
                    <div className="event-description">
                        <p>Monday, 12 Apr. 2024, 2.00 PM – 11.00 PM</p>
                        <p>4517 Washington Ave. Manchester, Kentucky 39495</p>
                    </div>
                    <div className="wpo-event-wrap">
                        <div className="wpo-event-inner">
                            {Events.map((event, eitem) => (
                                <div className="wpo-event-item" key={eitem}>
                                    <div className="wpo-event-text">
                                        <i><img src={event.Icon} alt="" /></i>
                                        <span>{event.title}</span>
                                    </div>
                                    <div className="wpo-event-time">
                                        <h4>{event.time}</h4>
                                        <i className="fa fa-heart"></i>
                                    </div>
                                </div>
                            ))}
                            <div className="line"></div>
                        </div>
                        <div className="shape-1"><img src={sImg1} alt="" /></div>
                        <div className="shape-2"><img src={sImg1} alt="" /></div>
                        <div className="shape-3"><img src={sImg2} alt="" /></div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default EventSection;