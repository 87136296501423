import React, { Fragment } from 'react';
import Navbar4 from '../../components/Navbar4/Navbar4';
import HeroRtl from '../../components/herortl/herortl';
import CoupleSection7 from '../../components/CoupleSection7/CoupleSection7';
import StorySection7 from '../../components/StorySection7/StorySection7';
import RsvpSectionS8 from '../../components/RsvpSectionS8/RsvpSectionS8';
import EventSectionS3 from '../../components/EventSection3/EventSection3';
import PartnerSection from '../../components/PartnerSection';
import BlogSection4 from '../../components/BlogSection4/BlogSection4';
import FooterRtl from '../../components/footerRtl/FooterRtl';
import Scrollbar from '../../components/scrollbar/scrollbar'



const HomePage7 = () => {

    return (
        <Fragment>
            <div dir="rtl" className="rtl-page">
                <Navbar4 hclass={'wpo-header-style-2'} />
                <HeroRtl />
                <CoupleSection7 />
                <StorySection7 ptClass={'pt-0'}/>
                <RsvpSectionS8 />
                <EventSectionS3 />
                <BlogSection4 bgClass={'soft-bg'} />
                <PartnerSection />
                <FooterRtl footerClass={'wpo-site-footer-s2'} />
                <Scrollbar /> 
            </div>
        </Fragment>
    )
};

export default HomePage7;