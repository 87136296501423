import React from 'react'
import { Link } from 'react-router-dom'
import Projects from '../../api/projects'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const ProjectSection = (props) => {
    return (
        <section className={`wpo-portfolio-section-s6 ${props.ptClass}`}>
            <div className="container-fluid">
                <div className="sortable-gallery">
                    <div className="gallery-filters"></div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-grids gallery-container clearfix">
                                {Projects.slice(0, 9).map((project, pitem) => (
                                    <div className="grid" key={pitem}>
                                        {project.title ?
                                            <div className="img-holder">
                                                <img src={project.pimg1} alt="" />
                                                <div className="portfolio-content">
                                                    <h4><Link onClick={ClickHandler} to={`/project-single/${project.slug}`}>{project.title}</Link></h4>
                                                    <span>{project.location}- {project.date}</span>
                                                </div>
                                            </div>
                                            :
                                            <div className="middle-content-wrap">
                                               <img src={project.pimg1} alt="" />
                                                <div className="wpo-section-title">
                                                    <h4>Our Amazing Work</h4>
                                                    <h2>Featured Wedding Story</h2>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectSection;