import React from "react";
import hero1 from '../../images/slider/rtl-hero-img.png'
import Shape1 from '../../images/slider/top-vector1.png'
import Shape2 from '../../images/slider/top-vector2.png'
import TimeCountDown from "../countdown";

const HeroRtl = () => {
    return (

        <section className="rtl-static-hero">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-7 col-lg-6 col-12">
                                <div className="wpo-hero-img">
                                    <img src={hero1} alt=""/>
                                </div>
                            </div>
                            <div className="col col-xl-5 col-lg-6 col-12">
                                <div className="wpo-static-hero-inner">
                                    <div className="shape-1"><img
                                            src={Shape1} alt=""/></div>
                                    <div className="slide-title">
                                        <h2>عمران ونعيمة</h2>
                                    </div>
                                    <div data-swiper-parallax="400" className="slide-text">
                                        <p>سنتزوج في ١٥ نوفمبر ٢٠٢٢</p>
                                    </div>
                                    <div className="wpo-wedding-date">
                                        <TimeCountDown/>
                                    </div>
                                    <div className="shape-2"><img
                                            src={Shape2} alt=""/></div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeroRtl;