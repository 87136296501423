import React from 'react'
import {Link} from 'react-router-dom'
import shape1 from '../../images/slider/invitation-shape-1.png'
import shape2 from '../../images/slider/invitation-shape-2.png'


const ShopBanner = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="wpo-offer-banner-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-lg-12 col-12">
                        <div className="offer-banner-text">
                            <div className="offer-banner-text-inner">
                                <span>Wedding Season Discounts</span>
                                <h2>Wedding Cake 50% Off</h2>
                                <Link onClick={ClickHandler} to="/home" className="theme-btn">Shop Now</Link>
                            </div>
                            <div className="frame-shape-1"><img src={shape1} alt=""/>
                            </div>
                            <div className="frame-shape-2"><img src={shape2} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ShopBanner;