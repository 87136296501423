import React from 'react'
import VideoModal from '../ModalVideo/VideoModal';
import vImg1 from '../../images/cta.jpg'

const VideoSection = (props) => {
    return (
        <section className={`wpo-video-section ${props.vClass}`}>
            <h2 className="hidden">some</h2>
            <div className="container-fluid">
                <div className="video-wrap">
                    <img src={vImg1} alt=""/>
                    <VideoModal />
                </div>
            </div>
        </section>
    )
}

export default VideoSection;