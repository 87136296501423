import React from 'react'
import sImg1 from '../../images/story/story-20.jpg'
import sImg2 from '../../images/story/story-21.jpg'
import sImg3 from '../../images/story/story-22.jpg'

import SectionTitle from '../SectionTitle'


const StorySection7 = (props) => {
    return (
        <section className={`wpo-story-section-s7 section-padding ${props.ptClass}`} id="story">
            <div className="container-fluid">
                <SectionTitle subTitle={'قصتنا'} MainTitle={'كيف حدث ذلك'} />
                <div className="wpo-story-wrap">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="wpo-story-item">
                                <div className="story-img-wrap">
                                    <div className="wpo-story-img">
                                        <img src={sImg1} alt="" />
                                    </div>
                                </div>
                                <div className="wpo-story-content">
                                    <span>12 فبراير 2016</span>
                                    <h2>أول مرة نلتقي</h2>
                                    <p>في مناسبة عائلية ، نلتقي ببعضنا البعض لأول مرة. ثم تعتقد عائلتنا أننا مثاليون
                                        لبعضنا البعض. لذلك قرروا الزواج منا في أقرب وقت ممكن.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="wpo-story-item">
                                <div className="story-img-wrap">
                                    <div className="wpo-story-img">
                                        <img src={sImg2} alt="" />
                                    </div>
                                </div>
                                <div className="wpo-story-content">
                                    <span>18 فبراير 2016</span>
                                    <h2>موعدنا الأول</h2>
                                    <p>في مناسبة عائلية ، نلتقي ببعضنا البعض لأول مرة. ثم تعتقد عائلتنا أننا مثاليون
                                        لبعضنا البعض. لذلك قرروا الزواج منا في أقرب وقت ممكن.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="wpo-story-item">
                                <div className="story-img-wrap">
                                    <div className="wpo-story-img">
                                        <img src={sImg3} alt="" />
                                    </div>
                                </div>
                                <div className="wpo-story-content">
                                    <span>18 فبراير 2016</span>
                                    <h2>قبلتنا الأولى</h2>
                                    <p>في مناسبة عائلية ، نلتقي ببعضنا البعض لأول مرة. ثم تعتقد عائلتنا أننا مثاليون
                                        لبعضنا البعض. لذلك قرروا الزواج منا في أقرب وقت ممكن.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection7;