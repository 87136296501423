import React from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/couple/couple-img-12.jpg'
import coupleImg2 from '../../images/couple/couple-img-13.jpg'
import shape1 from '../../images/couple/left-shape.png'
import shape2 from '../../images/couple/right-shape.png'

const CoupleSection5 = (props) => {

    return (
        <section className="wpo-couple-section-s4 section-padding pb-0" id="couple">
            <div className="container">
                <div className="couple-wrap">
                    <div className="row align-items-center">
                        <div className="col col-lg-6 col-12">
                            <div className="couple-item">
                                <div className="couple-img">
                                    <img src={coupleImg1} alt="" />
                                </div>
                                <div className="couple-text">
                                    <div className="couple-text-inner">
                                        <h3>Manshi Agarwal</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                            vitae nisl. fringilla pellesque amet tempus.</p>
                                        <div className="social">
                                            <ul>
                                                <li><Link to="/home3"><i className="ti-facebook"></i></Link></li>
                                                <li><Link to="/home3"><i className="ti-twitter-alt"></i></Link></li>
                                                <li><Link to="/home3"><i className="ti-instagram"></i></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-6 col-12">
                            <div className="couple-item">
                                <div className="couple-img">
                                    <img src={coupleImg2} alt="" />
                                </div>
                                <div className="couple-text">
                                    <div className="couple-text-inner">
                                        <h3>Akash Mishra</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                            vitae nisl. fringilla pellesque amet tempus.</p>
                                        <div className="social">
                                            <ul>
                                                <li><Link to="/home3"><i className="ti-facebook"></i></Link></li>
                                                <li><Link to="/home3"><i className="ti-twitter-alt"></i></Link></li>
                                                <li><Link to="/home3"><i className="ti-instagram"></i></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="left-shape"><img src={shape1} alt="" /></div>
            <div className="right-shape"><img src={shape2} alt="" /></div>
        </section>
    );
}

export default CoupleSection5;