import React from 'react'
import { Link } from 'react-router-dom';
import abimg from '../../images/about/img-1.jpg'
import sign from '../../images/about/signature.png'

const About = (props) => {
    return (
        <section className="wpo-about-section">
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row align-items-center">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <div className="about-right-img">
                                    <img src={abimg} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <p>Mignissim adipiscing pretium libero at tristique. Diam sit mauris a interdum. In
                                    tristique eu vestibulum id amet malesuada. Quis morbi morbi sed volutpat tristique.
                                    Lacinia praesent dictum morbi et fermentum. Blandit cras ut sed quisque pellentesque
                                    iaculis elit. Lectus adipiscing semper odio lacus urna. Sit a sit ac blandit. Cursus
                                    donec mollis elit arcu donec aliquam.</p>
                                <div className="about-info-wrap">
                                    <div className="about-info">
                                        <h5>Kristin Watson</h5>
                                        <span>- CEO of the company</span>
                                    </div>
                                    <div className="about-sign">
                                        <img src={sign} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-12 col-12">
                            <div className="about-social">
                                <ul>
                                    <li>
                                        <Link to="/home-8">
                                            <i className="ti-facebook"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home-8">
                                            <i className="ti-twitter-alt"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home-8">
                                            <i className="ti-instagram"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home-8">
                                            <i className="ti-pinterest"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;