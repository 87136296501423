import React from "react";
import { Link } from 'react-router-dom'

import hero1 from '../../images/slider/shop-right.jpg'
import shape1 from '../../images/slider/s-shape-1.png'
import shape2 from '../../images/slider/s-shape-2.png'


const Hero10 = () => {
    return (

        <section className="shop-hero">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col col-lg-6 col-12">
                        <div className="wpo-static-hero-inner">
                            <div className="slide-shape">
                                <img src={shape1} alt=""/>
                            </div>
                            <div className="slide-title">
                                <h2>Diamond Earring</h2>
                            </div>
                            <div className="slide-price">
                                <h4>$850.00</h4>
                            </div>
                            <div data-swiper-parallax="400" className="slide-text">
                                <p>Here we have the best wedding jewelry you have ever seen. The elegant and brand new look is waiting for you in your big day. Lets Ready with Sukun.</p>
                            </div>
                            <div data-swiper-parallax="400" className="slide-btn-wtap">
                                <ul>
                                    <li><Link to="/shop" className="theme-btn">Shop Now</Link></li>
                                    <li><Link to="/shop"><i className="fi flaticon-heart"></i></Link></li>
                                </ul>
                            </div>
                            <div className="clearfix"></div>
                            <div className="slide-shape">
                                <img src={shape2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="shop-right">
                            <img src={hero1} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero10;