import React from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/couple/couple-img-14.jpg'
import icon1 from '../../images/couple/bride.svg'
import icon2 from '../../images/couple/groom.svg'

const CoupleSection6 = (props) => {

    return (
        <section className="wpo-couple-section-s5 section-padding pb-0" id="couple">
            <div className="container-fluid">
                <div className="wpo-couple-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="text-grid bride">
                                <div className="couple-text">
                                    <i><img src={icon1} alt="" /></i>
                                    <h3>Esabella Bell</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                        vitae nisl. fringilla pellesque amet tempus.</p>
                                    <div className="social">
                                        <ul>
                                            <li><Link to="/home3"><i className="ti-facebook"></i></Link></li>
                                            <li><Link to="/home3"><i className="ti-twitter-alt"></i></Link></li>
                                            <li><Link to="/home3"><i className="ti-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="middle-couple-pic">
                                <div className="middle-couple-pic-inner">
                                    <img src={coupleImg1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="text-grid groom">
                                <div className="couple-text">
                                    <i><img src={icon2} alt="" /></i>
                                    <h3>William Max</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                        vitae nisl. fringilla pellesque amet tempus.</p>
                                    <div className="social">
                                        <ul>
                                            <li><Link to="/home3"><i className="ti-facebook"></i></Link></li>
                                            <li><Link to="/home3"><i className="ti-twitter-alt"></i></Link></li>
                                            <li><Link to="/home3"><i className="ti-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoupleSection6;