import React from "react";
import Services from '../../api/service'
import { Link } from 'react-router-dom'
import SectionTitle from "../SectionTitle";


const ServiceSection = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (

        <section className="wpo-service-section section-padding" id="Service">
            <div className="container">
                <SectionTitle subTitle={'Our Services'} MainTitle={'What We Offer For You'} />
                <div className="wpo-service-wrap">
                    <div className="row">
                        {Services.slice(0, 4).map((service, sitem) => (
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={sitem}>
                                <div className="wpo-service-item">
                                    <div className="wpo-service-img">
                                        <img src={service.simg} alt="" />
                                    </div>
                                    <div className="wpo-service-text">
                                        <h3>{service.title}</h3>
                                        <p>Aliquet magna menas esmod pelque diam. Maenas massa tincint bidum tidunt. </p>
                                        <Link onClick={ClickHandler} to={`/service-single/${service.slug}`} className="more">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceSection;