import React from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/couple/couple-img-1.jpg'
import coupleImg2 from '../../images/couple/couple-img-2.jpg'
import coupleImg3 from '../../images/couple/flower1.png'
import coupleImg4 from '../../images/couple/flower2.png'
import icon1 from '../../images/couple/bride.svg'
import icon2 from '../../images/couple/groom.svg'

const CoupleSection = (props) => {

    return (
        <section className="wpo-couple-section section-padding pt-2" id="couple">
            <div className="container">
                <div className="couple-area clearfix">
                    <div className="couple-wrap">
                        <div className="row align-items-center gx-5">
                            <div className="col col-md-6 col-12">
                                <div className="couple-item">
                                    <div className="couple-img">
                                        <img src={coupleImg1} alt=""/>
                                    </div>
                                    <div className="couple-text">
                                        <i><img src={icon1} alt=""/></i>
                                        <h3>Esabella Bell</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                            vitae nisl. fringilla pellesque amet tempus.</p>
                                        <div className="social">
                                            <ul>
                                                <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                                <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                                <li><Link to="/"><i className="ti-instagram"></i></Link></li>
                                            </ul>
                                        </div>
                                        <div className="couple-bg">
                                            <svg viewBox="0 0 500 433" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0 28.0003C0 28.0003 40 -20.2942 78 11.0001C90.075 20.9443 94.3766 37.833 95.1425 54.6979C95.9162 71.7365 117.555 85.4249 131.164 75.1428C133.036 73.7282 135.17 72.6975 137.441 72.11L142.442 70.8167C168.453 64.0898 194.316 82.2112 196.874 108.956L197.393 114.379C200.295 144.721 230.211 164.837 259.405 156.079L263 155C290.305 139.265 324.668 157.485 326.966 188.914L328.735 213.107C329.843 228.267 342.466 240 357.667 240C359.882 240 362.089 239.747 364.246 239.244L376.055 236.494C404.616 229.843 431.332 253.052 428.738 282.263L427.564 295.484C425.743 315.988 443.286 332.954 463.719 330.449C482.999 328.085 500 343.128 500 362.553V433H0V28.0003Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6 col-12">
                                <div className="couple-item">
                                    <div className="couple-img">
                                        <img src={coupleImg2} alt=""/>
                                    </div>
                                    <div className="couple-text">
                                        <i><img src={icon2} alt=""/></i>
                                        <h3>William Max</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna orci auctor
                                            vitae nisl. fringilla pellesque amet tempus.</p>
                                        <div className="social">
                                            <ul>
                                                <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                                <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                                <li><Link to="/"><i className="ti-instagram"></i></Link></li>
                                            </ul>
                                        </div>
                                        <div className="couple-bg">
                                            <svg viewBox="0 0 500 433" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M500 28.0003C500 28.0003 460 -20.2942 422 11.0001C409.925 20.9443 405.623 37.833 404.858 54.6979C404.084 71.7365 382.445 85.4249 368.836 75.1428C366.964 73.7282 364.83 72.6975 362.559 72.11L357.558 70.8167C331.547 64.0898 305.684 82.2112 303.126 108.956L302.607 114.379C299.705 144.721 269.789 164.837 240.595 156.079L237 155C209.695 139.265 175.332 157.485 173.034 188.914L171.265 213.107C170.157 228.267 157.534 240 142.333 240C140.118 240 137.911 239.747 135.754 239.244L123.945 236.494C95.3837 229.843 68.6684 253.052 71.2621 282.263L72.436 295.484C74.2567 315.988 56.7136 332.954 36.2814 330.449C17.0009 328.085 0 343.128 0 362.553V433H500V28.0003Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shape-1"><img src={coupleImg3} alt=""/></div>
                        <div className="shape-2"><img src={coupleImg4} alt=""/></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoupleSection;