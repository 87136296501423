import React from 'react'
import shape1 from '../../images/rsvp/top-shape.png'
import shape2 from '../../images/rsvp/bottom-shape.png'
import shape3 from '../../images/rsvp/l-flower1.png'
import shape4 from '../../images/rsvp/l-flower2.png'
import RSVPFrom from '../RSVPFrom/RSVPFrom';

const RsvpSectionS6 = (props) => {
    return (
        <section className={`wpo-contact-section-s6 section-padding ${props.rClass}`} id="rsvp">
            <div className="container">
                <div className="contact-wrap">
                    <div className="wpo-contact-section-wrapper">
                        <div className="wpo-contact-form-area">
                            <div className="wpo-section-title">
                                <h2>Are You Attending?</h2>
                            </div>
                            <RSVPFrom />
                        </div>
                    </div>
                    <div className="shape-1"><img src={shape1} alt=""/></div>
                    <div className="shape-2"><img src={shape2} alt=""/></div>
                </div>
            </div>
            <div className="left-shape-1"><img src={shape3} alt=""/></div>
            <div className="left-shape-2"><img src={shape4} alt=""/></div>
        </section>
    )
}

export default RsvpSectionS6;