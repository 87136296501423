import React from 'react'
import RSVPFrom from '../RSVPFrom/RSVPFrom'
import shape1 from '../../images/rsvp/left-shape3.png'
import shape2 from '../../images/rsvp/right-shape3.png'


const RsvpSectionS3 = (props) => {
    return (
        <section className={`wpo-contact-section-s3 section-padding ${props.rClass}`} id="rsvp">
            <div className="container">
                <div className="contact-wrap">
                    <div className="wpo-contact-section-wrapper">
                        <div className="wpo-contact-form-area">
                            <div className="wpo-section-title">
                                <h2>Are You Attending?</h2>
                            </div>
                            <RSVPFrom />
                        </div>
                    </div>
                    <div className="shape-1"><img src={shape1} alt=""/></div>
                    <div className="shape-2"><img src={shape2} alt=""/></div>
                </div>
            </div>
        </section>
    )
}

export default RsvpSectionS3;