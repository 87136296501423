import React from 'react'
import shape1 from '../../images/rsvp/left-shape.png'
import shape2 from '../../images/rsvp/right-shape.png'
import mainimg from '../../images/rsvp/img-1.png'
import RSVPFrom from '../RSVPFrom/RSVPFrom'
import MarqueeTitle from '../MarqueeTitle'


const RsvpSection = (props) => {
    return (
        <section className={`wpo-contact-section ${props.rClass} section-padding`} id="rsvp">
            <div className="container-fluid">
                <div className="contact-wrap">
                    <div className="row">
                        <div className="col col-xl-8 col-lg-7 col-md-12 col-12">
                            <div className="contact-img-wrap">
                                <div className="contact-img">
                                    <img src={mainimg} alt=""/>
                                </div>
                                <div className="back-shape">
                                    <svg viewBox="0 0 693 954" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M15 346.5C15 163.418 163.418 15 346.5 15C529.582 15 678 163.418 678 346.5V939H15V346.5Z"
                                            stroke="#9F7B59" strokeWidth="30" />
                                        <rect x="50" y="168" width="30" height="765" fill="#9F7B59" />
                                        <rect x="100" y="106" width="30" height="827" fill="#9F7B59" />
                                        <rect x="150" y="67" width="30" height="866" fill="#9F7B59" />
                                        <rect x="200" y="45" width="30" height="879" fill="#9F7B59" />
                                        <rect x="250" y="23" width="30" height="910" fill="#9F7B59" />
                                        <rect x="300" y="14" width="30" height="919" fill="#9F7B59" />
                                        <rect x="350" y="14" width="30" height="919" fill="#9F7B59" />
                                        <rect x="400" y="14" width="30" height="919" fill="#9F7B59" />
                                        <rect x="450" y="34" width="30" height="899" fill="#9F7B59" />
                                        <rect x="500" y="67" width="30" height="866" fill="#9F7B59" />
                                        <rect x="550" y="100" width="30" height="833" fill="#9F7B59" />
                                        <rect x="600" y="148" width="30" height="785" fill="#9F7B59" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-4 col-lg-5 col-md-12 col-12">
                            <div className="wpo-contact-section-wrapper">
                                <div className="wpo-contact-form-area">
                                    <div className="wpo-section-title">
                                        <h2>Are You Attending?</h2>
                                    </div>
                                    <RSVPFrom />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-1"><img
                            src={shape1} alt=""/></div>
                    <div className="shape-2"><img
                            src={shape2} alt=""/></div>
                </div>
            </div>
            <MarqueeTitle/>
        </section>
    )
}

export default RsvpSection;