import React from 'react'
import pImg1 from '../../images/portfolio/19.jpg'
import pImg2 from '../../images/portfolio/20.jpg'
import pImg3 from '../../images/portfolio/21.jpg'
import pImg4 from '../../images/portfolio/22.jpg'
import pImg5 from '../../images/portfolio/23.jpg'
import pImg6 from '../../images/portfolio/24.jpg'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import SectionTitle from '../SectionTitle'

const Portfolios = [
    {
        Pimg: pImg1,
    },
    {
        Pimg: pImg2,
    },
    {
        Pimg: pImg3,
    },
    {
        Pimg: pImg4,
    },
    {
        Pimg: pImg5,
    },
    {
        Pimg: pImg6,
    }

]

const PortfolioSectionS3 = (props) => {

    const [open, setOpen] = React.useState(false);

    return (

        <section className={`wpo-portfolio-section section-padding ${props.ptClass}`} id="gallery">
            <div className="container">
                <SectionTitle subTitle={'Sweet Memories'} MainTitle={'Our Captured Moment'} />
                <div className="gallery-main-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="sortable-gallery">
                                <div className="row portfolio-grids style-1 clearfix">
                                    {Portfolios.map((image, i) => (
                                        <div className="col-lg-4 col-md-6 col-12 grid" key={i}>
                                            <div className="img-holder" onClick={() => setOpen(true)}>
                                                <div className="inner-wrap">
                                                    <img src={image.Pimg} alt="" className="img img-responsive" />
                                                    <div className="hover-content">
                                                        <i className="ti-plus"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    { src: pImg1 },
                    { src: pImg2 },
                    { src: pImg3 },
                    { src: pImg4 },
                    { src: pImg5 },
                    { src: pImg6 },
                ]}
            />
        </section>
    )
}

export default PortfolioSectionS3;