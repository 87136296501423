import React from 'react'

const SectionTitle = (props) => {
    return (
        <div className={`wpo-section-title ${props.s2Class}`}>
            <h4 className="poort-text poort-in-right">{props.subTitle}</h4>
            <h2 className="poort-text poort-in-right">{props.MainTitle}</h2>
        </div>
    )
}

export default SectionTitle;