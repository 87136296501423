import React from 'react'
import shape1 from '../../images/rsvp/top-shape3.png'
import shape2 from '../../images/rsvp/top-shape4.png'
import shape3 from '../../images/rsvp/l-flower3.png'
import shape4 from '../../images/rsvp/l-flower4.png'
import RSVPFromRtl from '../RSVPFromRtl/RSVPFromRtl'

const RsvpSectionS8 = (props) => {
    return (
        <section className="wpo-contact-section-s6 s2 section-padding" id="rsvp">
            <div className="container">
                <div className="contact-wrap">
                    <div className="wpo-contact-section-wrapper">
                        <div className="wpo-contact-form-area">
                            <div className="wpo-section-title">
                                <h2>هل أنت حاضر؟</h2>
                            </div>
                            <RSVPFromRtl />
                        </div>
                    </div>
                    <div className="shape-1"><img src={shape1} alt=""/></div>
                    <div className="shape-2"><img src={shape2} alt=""/></div>
                </div>
            </div>
            <div className="left-shape-1"><img src={shape3} alt=""/></div>
            <div className="left-shape-2"><img src={shape4} alt=""/></div>
        </section>
    )
}

export default RsvpSectionS8;