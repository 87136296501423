import React, { useEffect, useRef } from 'react';
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/story/story-4.jpg'
import sImg2 from '../../images/story/story-5.jpg'
import sImg3 from '../../images/story/story-6.jpg'

import shape1 from '../../images/story/f-shape-1.png'
import shape2 from '../../images/story/f-shape-2.png'
import shape3 from '../../images/story/tassel.png'

import fshape from '../../images/story/shape3.png'
import fshape2 from '../../images/story/shape4.png'

const Storys = [
    {
        sImg: sImg1,
        title: 'First Time We Meet',
        date: '15 June 2014',
        description: 'Consectetur adipiscing elit. Fringilla at risus orci, tempus facilisi sed. Enim tortor, faucibus netus orci donec volutpat adipiscing. Sit condimentum elit convallis libero. Nunc in eu tellus ipsum placerat.',
    },
    {
        sImg: sImg2,
        title: 'Our First Date',
        date: '12 Dec 2019',
        description: 'Consectetur adipiscing elit. Fringilla at risus orci, tempus facilisi sed. Enim tortor, faucibus netus orci donec volutpat adipiscing. Sit condimentum elit convallis libero. Nunc in eu tellus ipsum placerat.',
        order1: 'order-lg-2 order-1',
        order2: 'order-lg-1 order-2',
    },
    {
        sImg: sImg3,
        title: 'She Said Yes!',
        date: '16 Jan 2023',
        description: 'Consectetur adipiscing elit. Fringilla at risus orci, tempus facilisi sed. Enim tortor, faucibus netus orci donec volutpat adipiscing. Sit condimentum elit convallis libero. Nunc in eu tellus ipsum placerat.',
    }

]


const StorySection2 = (props) => {

    const storyItemsRef = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
            storyItemsRef.current.forEach((item, index) => {
                const rect = item.getBoundingClientRect();
                const prevItem = storyItemsRef.current[index - 1];

                if (rect.top <= 230 && rect.bottom >= 230) {
                    item.classList.add('sticky');
                    if (prevItem) {
                        prevItem.classList.add('fade-out');
                    }
                } else {
                    item.classList.remove('sticky');
                    if (prevItem) {
                        prevItem.classList.remove('fade-out');
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section className="wpo-story-section-s2 section-padding pb-0" id="story">
            <div className="container">
                <SectionTitle subTitle={'Our Story'} MainTitle={'Our Sweet Love Story'} />
                <div className="wpo-story-wrap">
                    {
                        Storys.map((story, index) => (
                            <div
                                key={index}
                                className="wpo-story-item"
                                ref={(el) => (storyItemsRef.current[index] = el)}
                            >
                                <div className="row">
                                    <div className="col col-lg-6 col-12">
                                        <div className="wpo-story-img">
                                            <img src={story.sImg} alt="" />
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12">
                                        <div className="wpo-story-content">
                                            <i><img src={shape1} alt="" /></i>
                                            <h2>{story.title}</h2>
                                            <span>{story.date}</span>
                                            <p>{story.description}</p>
                                            <i><img src={shape2} alt="" /></i>
                                            <div className="top-shape"><img src={shape3} alt="" /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))

                    }
                </div>
            </div>
            <div className="flower-shape-1">
                <div className="flower-sticky">
                    <img src={fshape} alt="" />
                </div>
            </div>
            <div className="flower-shape-2">
                <div className="flower-sticky">
                    <img src={fshape2} alt="" />
                </div>
            </div>
        </section>
    )
}

export default StorySection2;