import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero4 from '../../components/hero4/hero4';
import CoupleSection3 from '../../components/CoupleSection3/CoupleSection3';
import BrideGrooms from '../../components/BrideGrooms';
import RsvpSectionS4 from '../../components/RsvpSectionS4/RsvpSectionS4';
import EventSection2 from '../../components/EventSection2/EventSection2';
import PortfolioSectionS3 from '../../components/PortfolioSectionS3/PortfolioSectionS3';
import BlogSection from '../../components/BlogSection/BlogSection';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'



const HomePage4 = () => {

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-2'}/>
            <Hero4 />
            <CoupleSection3 />
            <PortfolioSectionS3 ptClass="pt-0"/>
            <BrideGrooms />
            <RsvpSectionS4/>
            <EventSection2/>
            <BlogSection bgClass={'wpo-blog-section-s2 '}/> 
            <PartnerSection />
            <Footer footerClass={'wpo-site-footer-s2'}/>
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage4;