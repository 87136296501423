import React from 'react'
import SectionTitle from '../SectionTitle';
import Lightbox from "yet-another-react-lightbox";
import pImg1 from '../../images/portfolio/9.jpg'
import pImg2 from '../../images/portfolio/10.jpg'
import pImg3 from '../../images/portfolio/11.jpg'
import pImg4 from '../../images/portfolio/12.jpg'
import pImg5 from '../../images/portfolio/13.jpg'
import pImg6 from '../../images/portfolio/14.jpg'
import pImg7 from '../../images/portfolio/15.jpg'
import pImg8 from '../../images/portfolio/16.jpg'
import pImg9 from '../../images/portfolio/17.jpg'
import pImg10 from '../../images/portfolio/18.jpg'
import clothespin from '../../images/portfolio/clothespin.png'

const Portfolios = [
    {
        Pimg: pImg1,
    },
    {
        Pimg: pImg2,
    },
    {
        Pimg: pImg3,
    },
    {
        Pimg: pImg4,
    },
    {
        Pimg: pImg5,
    },
    {
        gClass: 'full',
    },
    {
        Pimg: pImg6,
    },
    {
        Pimg: pImg7,
    },
    {
        Pimg: pImg8,
    },
    {
        Pimg: pImg9,
    },
    {
        Pimg: pImg10,
    },

]

const PortfolioSectionS2 = (props) => {

    const [open, setOpen] = React.useState(false);

    return (

        <section className={`wpo-portfolio-section-s2 section-padding ${props.prClass}`} id="gallery">
            <div className="container-fluid">
                <SectionTitle subTitle={'Sweet Memories'} MainTitle={'Our Captured Moment'} />
                <div className="gallery-main-wrap">
                    <div className="sortable-gallery">
                        <div className="portfolio-top">
                            <div className="line">
                                <svg height="127" viewBox="0 0 1920 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M-6 1C173.333 95 814 226.6 1942 1" stroke="#CAA6C7" strokeWidth="2" />
                                </svg>
                            </div>
                            <div className="portfolio-grids clearfix">
                                {Portfolios.slice(0, 11).map((image, i) => (
                                    <div className={`grid ${image.gClass? image.gClass : ''}`} key={i}>
                                        {image.Pimg ?
                                            <>
                                                <div className="img-holder" onClick={() => setOpen(true)}>
                                                    <div className="inner-wrap">
                                                        <img src={image.Pimg} alt="" className="img img-responsive" />
                                                        <div className="hover-content">
                                                            <i className="ti-plus"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clothespin">
                                                    <img src={clothespin} alt="" />
                                                </div>
                                            </>
                                            : 
                                            <div className="line">
                                                <svg height="127" viewBox="0 0 1920 127" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M-6 1C173.333 95 814 226.6 1942 1" stroke="#CAA6C7"
                                                        strokeWidth="2" />
                                                </svg>
                                            </div>
                                            
                                            }

                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    { src: pImg1 },
                    { src: pImg2 },
                    { src: pImg3 },
                    { src: pImg4 },
                    { src: pImg5 },
                    { src: pImg6 },
                ]}
            />
        </section>
    )
}

export default PortfolioSectionS2;