import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2';
import Hero6 from '../../components/hero6/hero6';
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import CoupleSection5 from '../../components/CoupleSection5/CoupleSection5';
import StorySection5 from '../../components/StorySection5/StorySection5';
import PortfolioSectionS5 from '../../components/PortfolioSectionS5/PortfolioSectionS4';
import RsvpSectionS6 from '../../components/RsvpSectionS6/RsvpSectionS6';
import PartnerSection from '../../components/PartnerSection';
import BlogSection3 from '../../components/BlogSection3/BlogSection3';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'

const HomePage6 = () => {

    return (
        <Fragment>
            <Navbar2/>
            <Hero6 />
            <WeddingDate wClass={'wpo-wedding-date-s4 pb-0'}/>
            <CoupleSection5 />
            <StorySection5 />
            <PortfolioSectionS5 />
            <RsvpSectionS6 rClass={'pt-0'}/>
            <BlogSection3 bgClass={'section-padding soft-bg'}/>
            <PartnerSection />
            <Footer footerClass={'wpo-site-footer-s2'}/>
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage6;