import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero3 from '../../components/hero3/hero3';
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import StorySection3 from '../../components/StorySection3/StorySection3';
import PortfolioSectionS3 from '../../components/PortfolioSectionS3/PortfolioSectionS3';
import RsvpSectionS3 from '../../components/RsvpSectionS3/RsvpSectionS3';
import EventSection2 from '../../components/EventSection2/EventSection2';
import PartnerSection from '../../components/PartnerSection';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'




const HomePage3 = () => {

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-2'}/>
            <Hero3 />
            <WeddingDate wClass={'wpo-wedding-date-s2'}/>
            <StorySection3 ptClass={'pt-0'}/>
            <PortfolioSectionS3 ptClass="pt-0"/>
            <RsvpSectionS3 rClass={'pt-0'}/>
            <EventSection2/>
            <BlogSection bgClass={'wpo-blog-section-s2'}/>
            <PartnerSection />
            <Footer footerClass={'wpo-site-footer-s2'}/>
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage3;