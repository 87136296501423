import React from 'react'
import bg from '../../images/rsvp/bg.jpg'
import RSVPFrom from '../RSVPFrom/RSVPFrom';

const RsvpSectionS4 = (props) => {
    return (
        <section className="wpo-contact-section-s4 section-padding pt-0" id="rsvp">
            <div className="wpo-contact-section-inner" style={{ background: `url(${bg})no-repeat center top / cover` }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-xl-5 col-lg-7 col-md-12 col-12">
                            <div className="wpo-contact-section-wrapper">
                                <div className="wpo-contact-form-area">
                                    <div className="wpo-section-title">
                                        <h2>Are You Attending?</h2>
                                    </div>
                                    <RSVPFrom />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RsvpSectionS4;