import React, { useEffect, useRef } from 'react';
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/story/story-1.jpg'
import sImg2 from '../../images/story/story-2.jpg'
import sImg3 from '../../images/story/story-3.jpg'

import pin from '../../images/story/pin.svg'
import fshape from '../../images/story/f-shape-1.png'
import fshape2 from '../../images/story/f-shape-2.png'

import shape1 from '../../images/story/shape1.png'
import shape2 from '../../images/story/shape2.png'


const Storys = [
    {
        sImg: sImg1,
        title: 'First Time We Meet',
        date: '12 Feb 2016',
        description: 'Lorem ipsum dolor sit amet, constetur kiy adicng elit. Ultricies nulla mi tempus mcorper for praesent. Ultricies interdum hy volutpat morbi nam ornare neque elit leo, diam. Malesuada enim ac amurna tempor asr btyrfgvel duis.',
    },
    {
        sImg: sImg2,
        title: 'Our First Date',
        date: '25 Mar 2017',
        description: 'Lorem ipsum dolor sit amet, constetur kiy adicng elit. Ultricies nulla mi tempus mcorper for praesent. Ultricies interdum hy volutpat morbi nam ornare neque elit leo, diam. Malesuada enim ac amurna tempor asr btyrfgvel duis.',
        order1: 'order-lg-2 order-1',
        order2: 'order-lg-1 order-2',
    },
    {
        sImg: sImg3,
        title: 'She Said Yes!',
        date: '18 Sep 2020',
        description: 'Lorem ipsum dolor sit amet, constetur kiy adicng elit. Ultricies nulla mi tempus mcorper for praesent. Ultricies interdum hy volutpat morbi nam ornare neque elit leo, diam. Malesuada enim ac amurna tempor asr btyrfgvel duis.',
    }

]


const StorySection = (props) => {

    const storyItemsRef = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
            storyItemsRef.current.forEach((item, index) => {
                const rect = item.getBoundingClientRect();
                const prevItem = storyItemsRef.current[index - 1];

                if (rect.top <= 230 && rect.bottom >= 230) {
                    item.classList.add('sticky');
                    if (prevItem) {
                        prevItem.classList.add('fade-out');
                    }
                } else {
                    item.classList.remove('sticky');
                    if (prevItem) {
                        prevItem.classList.remove('fade-out');
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <section className={`wpo-story-section section-padding pb-0 ${props.stClass}`} id="story">
            <div className="container">
                <SectionTitle subTitle={'Our Story'} MainTitle={'Our Sweet Love Story'} />
                <div className="wpo-story-wrap">
                    {Storys.map((story, index) => (
                        <div
                            key={index}
                            className="wpo-story-item"
                            ref={(el) => (storyItemsRef.current[index] = el)}
                        >
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="wpo-story-img">
                                        <img src={story.sImg} alt="" />
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <div className="wpo-story-content">
                                        <div className="pin">
                                            <img src={pin} alt="" />
                                        </div>
                                        <i><img src={fshape} alt="" /></i>
                                        <h2>{story.title}</h2>
                                        <span>{story.date}</span>
                                        <p>{story.description}</p>
                                        <i><img src={fshape2} alt="" /></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flower-shape-1">
                <div className="flower-sticky">
                    <img src={shape1} alt="" />
                </div>
            </div>
            <div className="flower-shape-2">
                <div className="flower-sticky">
                    <img src={shape2} alt="" />
                </div>
            </div>
        </section>
    )
}

export default StorySection;