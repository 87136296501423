import pimg1 from '../images/portfolio/42.jpg'
import pimg2 from '../images/portfolio/43.jpg'
import pimg3 from '../images/portfolio/44.jpg'
import pimg4 from '../images/portfolio/45.jpg'
import pimg5 from '../images/portfolio/46.jpg'
import pimg6 from '../images/portfolio/47.jpg'
import pimg7 from '../images/portfolio/48.jpg'
import pimg8 from '../images/portfolio/49.jpg'
import white from '../images/portfolio/white.jpg'

import pSimg1 from '../images/portfolio/42.jpg'
import pSimg2 from '../images/portfolio/43.jpg'
import pSimg3 from '../images/portfolio/44.jpg'
import pSimg4 from '../images/portfolio/45.jpg'
import pSimg5 from '../images/portfolio/46.jpg'
import pSimg6 from '../images/portfolio/47.jpg'
import pSimg7 from '../images/portfolio/48.jpg'
import pSimg8 from '../images/portfolio/49.jpg'



const Projects = [
    {
        id: '1',
        title:'Jack & Rose',
        slug:'Jack-Rose',
        pimg1:pimg1,
        pSimg:pSimg1,
        date:'Feb 2019',
        location:'Austria',
    },
    {
        id: '2',
        title:'Jenifer & Harry',
        slug:'Jenifer-Harry',
        pimg1:pimg2,
        pSimg:pSimg2,
        date:'Jan 2019',
        location:'Austria',
    },
    {
        id: '3',
        title:'Miller & Aiyana',
        slug:'Miller-Aiyana',
        pimg1:pimg3,
        pSimg:pSimg3,
        date:'Mar 2020',
        location:'Austria',
    },
    {
        id: '4',
        title:'Jane & Peter',
        slug:'Jane-Peter',
        pimg1:pimg4,
        pSimg:pSimg4,
        date:'Apr 2020',
        location:'Austria',
    },
    {
        id: '5',
        pimg1:white,
    },
    {
        id: '6',
        title:'James & Amelia',
        slug:'James-Amelia',
        pimg1:pimg5,
        pSimg:pSimg5,
        date:'Jun 2023',
        location:'Austria',
    },
    {
        id: '7',
        title:'Lucas & Mia',
        slug:'Lucas-Mia',
        pimg1:pimg6,
        pSimg:pSimg6,
        date:'Jun 2023',
        location:'Austria',
    },
    {
        id: '8',
        title:'Miller & Tuna',
        slug:'Miller-Tuna',
        pimg1:pimg7,
        pSimg:pSimg7,
        date:'Jun 2023',
        location:'Austria',
    },
    {
        id: '9',
        title:'William & Mone',
        slug:'William-Mone',
        pimg1:pimg8,
        pSimg:pSimg8,
        date:'Jun 2023',
        location:'Austria',
    }
]
export default Projects;