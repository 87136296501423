import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import PartnerSection from '../../components/PartnerSection';
import PortfolioSectionS5 from '../../components/PortfolioSectionS5/PortfolioSectionS4';

const PortfolioMasonaryPageS3 =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'}/>
            <PageTitle pageTitle={'Portfolio Masonry'} pagesub={'Portfolio'}/> 
            <PortfolioSectionS5 prClass={'pt-120'}/>
            <PartnerSection pClass={'pt-0'}/>
            <Footer footerClass={'wpo-site-footer-s1'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default PortfolioMasonaryPageS3;
