import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import WeddingCountdown from '../../components/WeddingCountdown/WeddingCountdown';
import Bg from '../../images/slider/invitation-3.jpg'
import frame1 from '../../images/slider/invitation-shape-1.png'
import frame2 from '../../images/slider/invitation-shape-2.png'


const ComingSoonPage = () => {
    const SubmitHandler = (e) => {
        e.preventDefault()
    }
    return (
        <Fragment>
            <div className="invitation-header style-2">
                <Link className="back-btn" to="/"><i className="ti-back-left"></i></Link>
            </div>
            <section className="wpo-coming-soon-area" style={{ background: `url(${Bg}) no-repeat center top / cover` }}>
                <div className="hero-container">
                    <div className="hero-inner">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="wpo-event-wrap">
                                        <div className="wpo-event-item">
                                            <div className="coming-soon-section">
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-8">
                                                        <div className="coming-soon-text">
                                                            <h2>Coming Soon</h2>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                                do eiusmod
                                                                tempor
                                                                incididunt ut labore et dolore.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="wpo-wedding-date">
                                                            <WeddingCountdown />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="wpo-coming-contact">
                                                            <form method="post" className="contact-validation-active"
                                                                id="contact-form-main" onSubmit={SubmitHandler}>
                                                                <div className="row justify-content-center">
                                                                    <div className="col-lg-5 col-sm-6 col-12">
                                                                        <input type="text" className="form-control" name="name"
                                                                            id="name" placeholder="Name*" />
                                                                    </div>
                                                                    <div className="col-lg-5 col-sm-6 col-12">
                                                                        <input type="email" className="form-control"
                                                                            name="email" id="email" placeholder="Email" />
                                                                    </div>
                                                                    <div className="col-lg-2 col-sm-4 col-6">
                                                                        <div className="submit-area">
                                                                            <button type="submit">Submit</button>
                                                                            <div id="loader">
                                                                                <i className="ti-reload"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="frame-shape-1"><img src={frame1} alt="" />
                                        </div>
                                        <div className="frame-shape-2"><img src={frame2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};
export default ComingSoonPage;
