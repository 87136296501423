import React, { useEffect, useRef } from 'react';

const useMarquee = (speed) => {
    const marqueeRefs = useRef([]);

    useEffect(() => {
        marqueeRefs.current.forEach((parentSelector) => {
            const clone = parentSelector.innerHTML;
            const firstElement = parentSelector.children[0];
            let i = 0;

            parentSelector.insertAdjacentHTML('beforeend', clone);
            parentSelector.insertAdjacentHTML('beforeend', clone);

            const intervalId = setInterval(() => {
                firstElement.style.marginLeft = `-${i}px`;
                if (i > firstElement.clientWidth) {
                    i = 0;
                }
                i += speed;
            }, 0);

            return () => clearInterval(intervalId);
        });
    }, [speed]);

    return marqueeRefs;
};

const Marquee = ({ children, speed = 0.2 }) => {
    const marqueeRefs = useMarquee(speed);

    return (
        <div className="marquee" ref={(el) => el && marqueeRefs.current.push(el)}>
            {children}
        </div>
    );
};

const MarqueeTitle = () => (
    <div className="bottom-text">
        <Marquee speed={0.2}>
            <h2>We Are Waiting For You to celebrate our Love.</h2>
        </Marquee>
    </div>
);

export default MarqueeTitle;
