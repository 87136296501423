import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import cImg1 from '../../images/slider/invitation-1.jpg'
import flower1 from '../../images/slider/left-shape-3.png'
import flower2 from '../../images/slider/left-shape-4.png'
import shape1 from '../../images/slider/ishape-1.svg'
import shape2 from '../../images/slider/ishape-2.svg'
import shape3 from '../../images/slider/ishape-3.svg'
import shape4 from '../../images/slider/ishape-4.svg'
import shape5 from '../../images/slider/ishape-5.svg'
import TimeCountDown from '../../components/countdown';
import LocationMap from './Modal';

const InvitationPage1 = () => {

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'}/>
            <section className="wpo-invitation-area section-padding">
                <div className="container-fluid">
                    <div className="wpo-invitation-wrap">
                        <div className="wpo-invitation-inner-item">
                            <div className="wpo-invitation-inner">
                                <div className="hero-text">
                                    <h2>Esabella & William</h2>
                                    <div>
                                        <span>We Are Getting Married In</span>
                                    </div>
                                    <p>12 . 12 . 2024</p>
                                </div>
                                <div className="middle-couple-pic">
                                    <div className="middle-couple-pic-inner">
                                        <img src={cImg1} alt="" />
                                    </div>
                                    <div className="shape"><img src={flower1} alt="" /></div>
                                    <div className="shape2"><img src={flower2} alt="" /></div>
                                </div>
                                <div className="wpo-wedding-date">
                                    <div className="wedding-date-wrap">
                                        <div className="row">
                                            <div className="col col-xs-12">
                                                <div className="clock-grids">
                                                    <div id="clock">
                                                        <TimeCountDown />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpo-event-text">
                                    <ul>
                                        <li>Monday, 12 Apr. 2024, 2.00 PM –
                                            11.00 PM</li>
                                        <li>4517 Washington Ave. Manchester,
                                            Kentucky 39495</li>
                                        <li><LocationMap /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="left-shape">
                    <div className="shape s1"><img src={shape1} alt="" /></div>
                    <div className="shape s2"><img src={shape2} alt="" /></div>
                    <div className="shape s3"><img src={shape3} alt="" /></div>
                    <div className="shape s4"><img src={shape4} alt="" /></div>
                    <div className="shape s5"><img src={shape5} alt="" /></div>
                </div>
                <div className="right-shape">
                    <div className="shape s1"><img src={shape5} alt="" /></div>
                    <div className="shape s2"><img src={shape4} alt="" /></div>
                    <div className="shape s3"><img src={shape3} alt="" /></div>
                    <div className="shape s4"><img src={shape2} alt="" /></div>
                    <div className="shape s5"><img src={shape1} alt="" /></div>
                </div>
            </section>
        </Fragment>
    )
};

export default InvitationPage1;