import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero2 from '../../components/hero2/hero2';
import WeddingDate from '../../components/WeddingDate/WeddingDate';
import StorySection2 from '../../components/StorySection2/StorySection2';
import PortfolioSectionS2 from '../../components/PortfolioSectionS2/PortfolioSectionS2';
import RsvpSectionS2 from '../../components/RsvpSectionS2/RsvpSectionS2';
import EventSection2 from '../../components/EventSection2/EventSection2';
import CoupleSection2 from '../../components/CoupleSection2/CoupleSection2';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'



const HomePage2 = () => {

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'}/>
            <Hero2 />
            <WeddingDate />
            <CoupleSection2 />
            <StorySection2 />
            <PortfolioSectionS2 prClass="pt-0"/>
            <RsvpSectionS2 rClass={'pt-0'}/>
            <EventSection2/>
            <BlogSection />
            <Footer/>
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage2;